import * as React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import RTLogo from '../../images/rt-logo.svg'
import ContactLauriEt from "../../components/ContactLauriEt";

import HeaderEt from '../../components/HeaderEt'
import FactoryTourEt from '../../components/FactoryTourEt'
import SampleProjectsEt from '../../components/SampleProjectsEt'
import SolutionsSelectionEt from '../../components/SolutionsSelectionEt'

import LayoutEt from "../../components/LayoutEt"
import Seo from "../../components/Seo"

const IndexPage = () => {

  return (
    <LayoutEt>
      <Seo title="Esileht" description="Puidust elementmajade tootja" lang="et" />

      <HeaderEt />
      <div className="hidden md:block bg-welement-pink">
        <div className="grid md:grid-cols-3 lg:grid-cols-2 gap-0">
          <div className="p-16 md:col-span-2 lg:col-span-1">
            <h1 className="title wel-mb-s">Puidust<br /> elementmajade<br /> tootja</h1>
            <p className="body-l wel-mb-s">Projekteerime<br />Toodame<br />Ehitame</p>
            <Link to="/et/kontakt" className="btn btn-red">Võta ühendust</Link>
          </div>
          <div>
            <StaticImage
              src="../../images/index-header.png"
              quality={100}
              formats={["auto", "webp", "avif"]}
              alt="Puidust elementmajade tootja"
              loading="eager"
              placeholder="blurred"
              className="h-128 w-full"
            />
          </div>
        </div>
      </div>

      <div className="block md:hidden bg-welement-pink relative">

        <div>
          <StaticImage
            src="../../images/index-header.png"
            quality={100}
            formats={["auto", "webp", "avif"]}
            alt="Puidust elementmajade tootja"
            loading="eager"
            placeholder="blurred"
            className="h-96 w-full"
          />
        </div>

        <div className="absolute top-0 left-0 w-full h-full bg-welement-black/40 text-welement-white pt-20 sm:pt-32">
          <div className="text-center">
            <h1 className="title wel-mb-s">Puidust elementmajade tootja</h1>
            <p className="body-l wel-mb-m">Projekteerime. Toodame. Ehitame.</p>
            <Link to="/et/kontakt" className="btn btn-red">Võta ühendust</Link>
          </div>

        </div>
      </div>


      <div className="mx-auto pt-20 px-8">
        <SolutionsSelectionEt />
      </div>

      <div className="container-base text-center">
        <h2 className="title wel-mb-m">Teenused</h2>

        <div className="grid grid-cols-2 md:grid-cols-4 gap-8 wel-mb-m">
          <div className="text-center">
            <StaticImage
              src="../../images/solutions/eramaja.png"
              quality={100}
              formats={["auto", "webp", "avif"]}
              alt="Eramajad"
              loading="eager"
              placeholder="blurred"
              className="w-full rounded wel-mb-s"
            />
            <p className="title-s">Eramajad</p>
          </div>
          <div className="text-center">
            <StaticImage
              src="../../images/solutions/ridaelamud.png"
              quality={100}
              formats={["auto", "webp", "avif"]}
              alt="Ridaelamud"
              loading="eager"
              placeholder="blurred"
              className="w-full rounded wel-mb-s"
            />
            <p className="title-s">Ridaelamud</p>
          </div>
          <div className="text-center">
            <StaticImage
              src="../../images/solutions/korter.png"
              quality={100}
              formats={["auto", "webp", "avif"]}
              alt="Kortermajad"
              loading="eager"
              placeholder="blurred"
              className="w-full rounded wel-mb-s"
            />
            <p className="title-s">Kortermajad</p>
          </div>
          <div className="text-center">
            <StaticImage
              src="../../images/solutions/fassaad.png"
              quality={100}
              formats={["auto", "webp", "avif"]}
              alt="Fassaadilahendused"
              loading="eager"
              placeholder="blurred"
              className="w-full rounded wel-mb-s"
            />
            <p className="title-s">Fassaadilahendused</p>
          </div>

        </div>

        <Link to="/et/teenused" className="btn btn-red">Vaata Lahendusi</Link>


      </div>

      <FactoryTourEt />

      <div className="container-base">
        <div className="mx-auto text-center w-3/4">
          <img src={RTLogo} alt="Rand &amp; Tuulberg" className="h-10 mx-auto mb-8" />

          <p className="body-l wel-mb-m">Welement kuulub Rand &amp; Tuulberg gruppi, mis on tegelenud ehitusmaterjalide tootmise, kinnisvara arendamise ja ehituse peatöövõtuga juba üle 25 aasta</p>
          <Link to="/et/ettevottest" className="btn btn-black">Meie lugu</Link>

        </div>
      </div>

      <div className="bg-welement-pink">
        <SampleProjectsEt />
      </div>

      <ContactLauriEt />

    </LayoutEt>
  )

};

export default IndexPage;
