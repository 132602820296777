import * as React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

const SampleProjects = () => {
  return (

    <div className="container-base text-center">
      <div className="section-1-2-center">
        <h2 className="title mb-8">Tehtud tööd</h2>
        <p className="body-l mb-16">Ehitustööstus on muutumas ja tehaseline ehitus on käimasoleva innovatsioonilaine üks peamisi suundasid. Vaata, kuidas oleme aidanud koostööpartneritel paremini tulemusteni jõuda.</p>
      </div>

      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-8 wel-mb-l">
        <div className="text-center">
          <StaticImage
            src="../images/portfolio/paju.png"
            quality={100}
            formats={["auto", "webp", "avif"]}
            alt="16-korruseline büroohoone Tartu südalinnas"
            loading="eager"
            placeholder="blurred"
            className="w-full rounded wel-mb-s"
          />
          <p className="title-s">Paju 2</p>
          <p className="body mb-1">16-korruseline büroohoone Tartu südalinnas</p>
          <p className="body-s">Tartu, Eesti</p>
        </div>

        <div className="text-center">
          <StaticImage
            src="../images/portfolio/hugo.png"
            quality={100}
            formats={["auto", "webp", "avif"]}
            alt="6-korruseline hostel Tartu südalinnas"
            loading="eager"
            placeholder="blurred"
            className="w-full rounded wel-mb-s"
          />
          <p className="title-s">Hugo.Stay</p>
          <p className="body mb-1">6-korruseline hostel Tartu südalinnas</p>
          <p className="body-s">Tartu, Eesti</p>
        </div>

        <div className="text-center">
          <StaticImage
            src="../images/portfolio/nupsik.png"
            quality={100}
            formats={["auto", "webp", "avif"]}
            alt="Ühekorruseline lasteaed Tartu äärelinnas"
            loading="eager"
            placeholder="blurred"
            className="w-full rounded wel-mb-s"
          />
          <p className="title-s">Nupsiku Lasteaed</p>
          <p className="body mb-1">Ühekorruseline lasteaed Tartu äärelinnas</p>
          <p className="body-s">Tartu, Eesti</p>
        </div>

        <div className="text-center">
          <StaticImage
            src="../images/portfolio/oa.png"
            quality={100}
            formats={["auto", "webp", "avif"]}
            alt="Kaks ridaelamut ja üks kahekorruseline korterelamu"
            loading="eager"
            placeholder="blurred"
            className="w-full rounded wel-mb-s"
          />
          <p className="title-s">Oa tänava arendus</p>
          <p className="body mb-1">Kaks ridaelamut ja üks kahekorruseline korterelamu</p>
          <p className="body-s">Tartu, Eesti</p>
        </div>

        <div className="text-center">
          <StaticImage
            src="../images/portfolio/holmenkollen.png"
            quality={100}
            formats={["auto", "webp", "avif"]}
            alt="Neli paarismaja Oslo kõige prestiižsemas Holmenkolleni piirkonnas."
            loading="eager"
            placeholder="blurred"
            className="w-full rounded wel-mb-s"
          />
          <p className="title-s">Holmenkolleni paarismajad</p>
          <p className="body mb-1">Neli paarismaja Oslo kõige prestiižsemas Holmenkolleni piirkonnas.</p>
          <p className="body-s">Oslo, Norra</p>
        </div>

        <div className="text-center">
          <StaticImage
            src="../images/portfolio/nyby.png"
            quality={100}
            formats={["auto", "webp", "avif"]}
            alt="Kahekorruselised hooldekodud Rootsis"
            loading="eager"
            placeholder="blurred"
            className="w-full rounded wel-mb-s"
          />
          <p className="title-s">Nyby LSS Hooldekodu</p>
          <p className="body mb-1">Kahekorruselised hooldekodud Rootsis</p>
          <p className="body-s">Nyby, Rootsi</p>
        </div>

        <div className="text-center">
          <StaticImage
            src="../images/portfolio/ahvenamaa.png"
            quality={100}
            formats={["auto", "webp", "avif"]}
            alt="Hotell Ahvenamaal"
            loading="eager"
            placeholder="blurred"
            className="w-full rounded wel-mb-s"
          />
          <p className="title-s">Ahvenamaa</p>
          <p className="body mb-1">Hotell Ahvenamaal</p>
          <p className="body-s">Ahvenamaa</p>
        </div>

        <div className="text-center">
          <StaticImage
            src="../images/portfolio/drobak.png"
            quality={100}
            formats={["auto", "webp", "avif"]}
            alt="Neli unikaalset eramaja Oslo külje all, Drobaki väikelinnas"
            loading="eager"
            placeholder="blurred"
            className="w-full rounded wel-mb-s"
          />
          <p className="title-s">Drobaki Majad</p>
          <p className="body mb-1">Neli unikaalset eramaja Oslo külje all, Drobaki väikelinnas</p>
          <p className="body-s">Drobak, Norra</p>
        </div>
      </div>

      <Link to="/et/portfoolio" className="btn btn-black">Vaata portfooliot</Link>
    </div>


  )
}

export default SampleProjects;
