import * as React from "react"

const FactoryTour = () => {
  return (
    <div className="bg-welement-pink">
      <div className="container-base">
        <div className="section-1-2-center">
          <h2 className="title mb-8">Tehase tuur</h2>
          <p className="body-l mb-16">Kui sul pole aega Tartusse külla tulla, 
siis võid alati meie tehasega tutvuda virtuaalselt. </p>
        </div>
        <iframe name="Tehase tuur" title="Tehase tuur" src="https://tour.panoee.com/welement-as/64097b572851d868d272f52c" frameBorder="0" width="100%" height="600px" scrolling="no" allow="vr,gyroscope,accelerometer" allowFullScreen="true" loading="lazy"></iframe>
      </div>

    </div>
  )
}

export default FactoryTour;
